import React from 'react';

type Audience = 'widget' | 'video';
const AudienceContext = React.createContext<Audience>('widget');

export function AudienceProvider({
  children,
  audience
}: React.PropsWithChildren<{ audience: Audience }>) {
  return <AudienceContext.Provider value={audience}>{children}</AudienceContext.Provider>;
}

export function useAudience() {
  return React.useContext(AudienceContext);
}

export const withAudience = <P extends { audience?: Audience }>(
  WrappedComponent: React.JSXElementConstructor<P>,
  options: { audience: Audience } | undefined
) => {
  return function AudienceComponent(props: P) {
    return (
      <AudienceProvider audience={(options?.audience || props.audience)!}>
        <WrappedComponent {...props} />
      </AudienceProvider>
    );
  };
};
