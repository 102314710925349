import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { Box } from '@material-ui/core';
import PlanCard from '../PlanCard';
import Dialog from '../Dialog';
import usePayment from '../../hooks/usePayment';
import { Crown } from '../Icon';

const useStyles = makeStyles(theme => ({
  paymentsModal: {
    '& .MuiPaper-root': {
      flex: 1
    }
  },
  title: {
    fontSize: 25,
    letterSpacing: '0px',
    lineHeight: '24px',
    textAlign: 'center',
    color: '#525252',
    position: 'relative !important',
    margin: ' 0 auto',
    fontWeight: 400
  },
  notification: {
    'margin-bottom': '19px',
    marginTop: 10,
    padding: '13px',
    'font-size': '14px',
    background: '#feb429',
    position: 'relative',
    display: 'flex',
    borderRadius: 6,
    '& a': {
      color: '#525252',
      textDecoration: 'underline'
    }
  },
  subtitle: {
    fontSize: 16,
    letterSpacing: '0px',
    textAlign: 'center',
    marginTop: 5,
    lineHeight: '36px',
    '@media only screen and (max-width: 425px)': {
      fontSize: 12,
      lineHeight: '24px'
    }
  },
  modalIconWrapper: {
    display: 'flex',
    '& svg path': {
      fill: '#fff'
    }
  },
  notificationInfo: {
    margin: 'auto 10px auto',
    textAlign: 'left',
    '@media only screen and (max-width: 425px)': {
      fontSize: 14,
      lineHeight: '18px'
    }
  }
}));

const messages = {
  // Show in all upgrade button modals with possible selection of categories
  widget: {
    title: 'Unlock WOXO at its fullest!',
    subtitle: 'Upgrade your plan now!'
  },
  // Only show when the user is on video page without category selector
  video: {
    title: "You're on the free plan",
    subtitle: 'Upgrade your plan to discover bulk video creation for REAL!'
  }
};

const PaymentsModal = ({ forwardRef, notificationText, defaultCategory, showCategorySelector }) => {
  const { handlePayment, loadingCard } = usePayment();
  const classes = useStyles();
  const modalType = showCategorySelector ? messages['widget'] : messages['video'];

  return (
    <Dialog
      forwardRef={forwardRef}
      fullWidth
      className={classes.paymentsModal}
      useClose
      classNameTitle={classes.title}
      maxWidth="lg"
      title={
        <>
          {!!notificationText && (
            <Box className={classes.notification}>
              <Box className={classes.modalIconWrapper}>
                <Crown viewBox="-2 -3 20 20" />
              </Box>
              <Box className={classes.notificationInfo}>{notificationText}</Box>
            </Box>
          )}
          <Box className={classes.title}>{modalType.title}</Box>
          <Box className={classes.subtitle}>{modalType.subtitle}</Box>
        </>
      }
    >
      <PlanCard
        onClick={handlePayment}
        loadingCard={loadingCard}
        showRecommended={false}
        showFree={false}
        showCategorySelector={showCategorySelector}
        defaultCategory={defaultCategory}
      />
    </Dialog>
  );
};

PaymentsModal.propTypes = {
  forwardRef: PropTypes.oneOfType([PropTypes.func, PropTypes.objectOf(PropTypes.any)]),
  notificationText: PropTypes.string,
  defaultCategory: PropTypes.string,
  showCategorySelector: PropTypes.bool
};

PaymentsModal.defaultProps = {
  forwardRef: () => {},
  defaultCategory: undefined,
  showCategorySelector: true
};

export default PaymentsModal;
