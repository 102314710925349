import React, { useContext, useRef, useState } from 'react';
import LogIn from '~/components/LogIn';
import SignUp from '~/components/SignUp';
import { useAudience } from '~/hooks/audience';
interface AuthModalContextProps {
  showSignUp: (callback?: (payload: unknown) => void) => void;
  showLogin: () => void;
  close: () => void;
}

const AuthModalContext = React.createContext<AuthModalContextProps>({
  showSignUp: () => {},
  showLogin: () => {},
  close: () => {}
});

export function AuthModalProvider({ children }: React.PropsWithChildren<{}>) {
  const signUpRef = React.useRef<any>(null);
  const loginRef = React.useRef<any>(null);
  const signUpFn = React.useRef<any>(null);

  const handleLogin = () => {
    signUpRef.current.hide();
    loginRef.current.show(signUpFn.current);
  };

  const handleSignUp = () => {
    loginRef.current.hide();
    signUpRef.current.show(signUpFn.current);
  };

  const dialogRef = useRef<any>(null);
  const audience = useAudience();
  const [value] = useState<AuthModalContextProps>(() => ({
    showSignUp: callback => {
      signUpFn.current = callback || null;
      signUpRef.current.show(signUpFn.current);
    },
    showLogin: () => {
      signUpFn.current = null;
      loginRef.current.show();
    },
    close: () => {
      dialogRef.current.hide();
    }
  }));

  return (
    <AuthModalContext.Provider value={value}>
      {children}
      <SignUp forwardRef={signUpRef} onLogin={handleLogin} audience={audience} />
      <LogIn forwardRef={loginRef} onSignUp={handleSignUp} />
    </AuthModalContext.Provider>
  );
}

export function useAuthModal() {
  return useContext(AuthModalContext);
}
